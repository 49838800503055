import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "managing-users"
    }}>{`Managing users`}</h1>
    <p>{`AutoBackup allows privileged Users (SUPER ADMIN) to add new Users and grant permissions for themselves or other Users.`}</p>
    <p>{`Granting permissions is done by attaching predefined roles (READ ONLY and ADMIN) to selected User.`}</p>
    <p><img alt="Users permissions view" src={require("../assets/permissions/users/users_view.png")} /></p>
    <h2 {...{
      "id": "available-roles"
    }}>{`Available roles`}</h2>
    <ul>
      <li parentName="ul">{`READ ONLY - allows to read all data for given customer,`}</li>
      <li parentName="ul">{`ADMIN - same privileges as READ ONLY and allows to create, update and delete resources (accounts, policies, setups etc.),`}</li>
      <li parentName="ul">{`SUPER ADMIN - same privileges as ADMIN and allows to grant permissions`}</li>
    </ul>
    <h2 {...{
      "id": "adding-a-new-user"
    }}>{`Adding a new user`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the "Create user" button.
`}<img alt="Create user button" src={require("../assets/permissions/users/users_create.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide user email and click the "Create" button.
`}<img alt="Create user form" src={require("../assets/permissions/users/users_form.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "granting-permissions"
    }}>{`Granting permissions`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Select a user and click the "Grant customer access" button.
`}<img alt="Grant customer access" src={require("../assets/permissions/users/user_view_no_customer.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select customer and roles from dropdowns and click "Save" button.
`}<img alt="Grant customer access form" src={require("../assets/permissions/users/grant_customer_access_form.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "removing-permissions"
    }}>{`Removing permissions`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the "Pencil" button.
`}<img alt="User view" src={require("../assets/permissions/users/user_view_with_customer.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click "Cross" to remove customer role from user.
`}<img alt="Remove access form" src={require("../assets/permissions/users/remove_access_form.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click "Save" button.
`}<img alt="Remove access form - Save button" src={require("../assets/permissions/users/remove_access_form_2.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm action by clicking "Remove" button.
`}<img alt="Remove access confirmation" src={require("../assets/permissions/users/remove_access_confirmation.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "deleting-a-user"
    }}>{`Deleting a user`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose a user and click "Delete" button from "Three dots".
`}<img alt="Delete user" src={require("../assets/permissions/users/delete_user.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm action by clicking "Delete" button.
`}<img alt="Delete user confirmation" src={require("../assets/permissions/users/delete_user_confirmation.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "delay-of-the-permissions-changes"
    }}>{`Delay of the permissions changes`}</h2>
    <p>{`The important thing to remember is that the changes in the permissions made by the user don't take effect immediately.
Usually, it takes up to 5 minutes for the new permissions to apply due to the asynchronous nature of the RBAC rules evaluation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      